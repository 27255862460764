<template>
  <div class="w-full h-full overflow-y-auto hide-scrollbar fixed districts-layout" v-show="dataLoading">
    <div
      class="relative flex justify-center items-center h-full w-full p-2 pt-12 lg:p-0 container rounded-xl" :class="imagesLoaded && 'container-visible'"
    >
      <div class="overflow-auto overflow-x-hidden hide-scrollbar h-full w-full  lg:h-3/4 lg:w-3/4 rounded-xl lg:rounded-4xl object-cover sm:h-5/6 sm:w-11/12 wrapper" >
        <div class="relative flex flex-col justify-center items-center h-full bg-bggray"
          v-for="(item, index) of steps" :key="index" :class="stepsClass(index, item)">
          <img v-if="item.image"
            class="h-full w-full object-cover absolute" :class="[index==0 && 'first-image', index==4 && 'hidden md:block']"
            :src="item.image"
          />
          <div v-if="item.image && !item.noOverlay" class="w-full overlay-districts" :class="index==0 && 'first-image-overlay'"></div>
          <component :is="item.comp" :MyDistrict="getMyDistrict" v-bind="item.props" @change-cover="changeCover"/>
        </div>
    
      </div>
    </div>
    <!-- MENUS -->
    <div
      id="leftMenu"
      class="hidden md:flex justify-center items-center w-screen absolute flex items-end -mt-16"
    >
      <div class="grid grid-cols-2 h-3/4 w-3/4 absolute">
        <div class="flex">
          <p
            class="text-base mr-4 2xl:mr-10"
            v-for="item in districtsItems"
            :key="item.id + ''"
          >
            <a
              @click="changeDistrict(item.id)"
              :id="item.id + 'districtLink'"
              class="cursor-pointer"
              :class="item.id == currentId ? 'font-bold' : ''"
            >
              {{ item.name }}
            </a>
          </p>
          <p class="text-base mr-4 2xl:mr-10">
            <a
              @click="openVineyard"
              class="cursor-pointer"
              :class="RightMenuSelected == 'vineyards' ? 'font-bold' : ''"
            >
              {{$t('FOOTER_DISTRICTS.VINEYARDS')}}
            </a>
          </p>
          <p class="text-base mr-4 2xl:mr-10">
            <a
              @click="openBeachfront"
              class="cursor-pointer"
              :class="RightMenuSelected == 'beachfront' ? 'font-bold' : ''"
            >
              {{$t('FOOTER_DISTRICTS.BEACHFRONT')}}
            </a>
          </p>
        </div>
        <div class="flex relative">
          <DistrictRightMenu
            @change-menu-tag="changeMenuTag"
            :currentStep="currentStep"
            v-show="RightMenuSelected == 'Districts'"
          />
          <VineyardRightMenu
            @change-menu-tag="changeMenuTag"
            :currentStep="currentStep"
            v-show="RightMenuSelected == 'vineyards'"
          />
          <BeachfrontRightMenu
            @change-menu-tag="changeMenuTag"
            :currentStep="currentStep"
            v-show="RightMenuSelected == 'beachfront'"
          />
        </div>
      </div>
    </div>
    <!-- END MENUS -->
  </div>
</template>
<style scoped>
.container{
  visibility: hidden;
  max-width: unset;
}
.container-visible{
  visibility: visible;
}
.wrapper{
    border-radius:3rem;
    scroll-behavior: smooth;
  }
@media (max-width: 720px){
  .wrapper{
    border-radius:0.75rem;
  }
  .z-10{
    margin: 0 -0.5rem;
  }
  .wrapper > div:last-child {
    height: auto;
    padding-top: 30px;
  }
}
.wrapper > div {
  min-height: 700px;
  height: 75vh;
}
.stick-to-center {
  position: sticky;
  bottom: -50%;
}
.fill-dirty-white{
  background: #F8F8F8;
}
.z-60{
  z-index:60;
}
.z-00{
  height: auto !important;
  margin: 0 -0.5rem;
  }
@media (max-height:930px) {
  
  .container{
    min-height: 930px;
  }
}
@media (max-width:1150px) {
  .wrapper > div:not(.fill-dirty-whitestick-to-center) {
    min-height: calc((var(--vh, 1vh) * 100) - 100px);
    height: 100%;
  }
  .districts-layout{
    overflow:hidden;
    -webkit-mask-image: -webkit-radial-gradient(white, black);
  }
  .wrapper{
    -webkit-mask-image: -webkit-radial-gradient(white, black);
    -webkit-overflow-scrolling: touch;
  }
  .container{
    min-height:calc((var(--vh, 1vh) * 100) - 100px) ;
    height: 100%;
  }
  .first-image,
  .first-image-overlay:after{
    border-top-left-radius: 0.75rem;
    border-top-right-radius: 0.75rem;
  }
}
</style>
<script>

// DISTRICTS
import DistrictPageCover from "@/components/districts/districtsPageComponents/DistrictPageCover";
import DistrictPageAbout from "@/components/districts/districtsPageComponents/DistrictPageAbout";
import DistrictPagePresents from "@/components/districts/districtsPageComponents/DistrictPagePresents";
import DistrictPageListings from "@/components/districts/DistrictPageListings";
import DistrictPageDidYouKnow from "@/components/districts/districtsPageComponents/DistrictPageDidYouKnow";
import DistrictPageVisit from "@/components/districts/districtsPageComponents/DistrictPageVisit";
import DistrictRightMenu from "@/components/districts/districtsPageComponents/DistrictRightMenu";
import DistrictPageMobile from "@/components/districts/districtsPageComponents/DistrictPageMobile";

// VINEYARDS
import VineyardRightMenu from "@/components/districts/vineyards/VineyardRightMenu";
import VineyardCover from "@/components/districts/vineyards/VineyardCover";
import VineyardAbout from "@/components/districts/vineyards/VineyardAbout";

// BEACHFRONT
import BeachfrontCover from "@/components/districts/beachfront/BeachfrontCover";
import BeachfrontRightMenu from "@/components/districts/beachfront/BeachfrontRightMenu";
import BeachfrontAbout from "@/components/districts/beachfront/BeachfrontAbout";

import DistrictsMixin from "@/models/districts/DistrictsModel";
import { DistrictForm } from "@/store/structs/districts";
import { District } from "@/store/structs/districts";
export default {
  name: "DistrictsPage",
  mixins: [DistrictsMixin],
  components: {
    // DISTRICTS
    DistrictPageCover,
    DistrictPageAbout,
    DistrictPagePresents,
    DistrictPageDidYouKnow,
    DistrictPageVisit,
    DistrictPageListings,
    DistrictRightMenu,
    // VINEYARDS
    VineyardRightMenu,
    VineyardCover,
    VineyardAbout,
    // BEACHFRONT
    BeachfrontCover,
    BeachfrontRightMenu,
    BeachfrontAbout,
  },
  data() {
    return {
      step:0,
      dataLoading: false,
      MyDistrict: DistrictForm,
      currentId:0,
      RightMenuSelected: "Districts",
      imagesLoaded:false,
      coverImage: undefined
    }
  },
  mounted(){
    if(this.$route.hash == '#listings_content'){
      let s = this;
      setTimeout(function(){
          s.changeMenuTag(5);
      }, 1000)
      
    }
  },
  setup(){
        let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    },
  async created() {

    const id = this.$route.params.slug;
    if(!Number.isInteger(+id)){
      this.currentId= -1
      this.RightMenuSelected = id
      await this.getDistricts();
      this.dataLoading = true;
      this.initEventsHandlers();
      return;
    }
    
  
    this.currentId = id;
    await this.getDistricts();
    // if(id == 9998){
    //   this.dataLoading = true;
    //   this.openVineyard()
    // }else if(id == 9999){
    //   this.dataLoading = true;
    //   this.openBeachfront()
    // }else{
    await this.getDistrict(id);
    this.MyDistrict = new DistrictForm(this.$store.state.districtsModule.district);
    // }
    this.dataLoading = true;
    this.initEventsHandlers();
  },
  methods: {
    initEventsHandlers(){
      this.coverImage = this.districtImage(this.MyDistrict.cover);
      setTimeout(() => {
        const root = document.querySelector('.wrapper');
        const list = root.children;
        const size = list.length;
        const observer = new IntersectionObserver((entries) => {
          for(const entry of entries){
            if(entry.isIntersecting){
              for(let i = 0; i<size;i++){
                if(list[i] === entry.target){
                  this.step = i;
                  return;
                }
              }
            }
          }
        }, {
          root,
          threshold:0.52
        });
        for(const el of list){
          observer.observe(el);
        }

        const imgs = root.querySelectorAll('img');
        const sizeImgs = imgs.length -1;
        let countImgs = 0;
        const loaded = () => {
          if(countImgs == sizeImgs){
            this.imagesLoaded = true;
          }else {
            countImgs++;
          }
        }
        for(const img of imgs){
          if (img.complete) {
            loaded()
          } else {
            img.addEventListener('load', loaded)
            img.addEventListener('error', loaded)
          }
        }
      });
    },
    changeCover(path){
      this.coverImage = this.districtImage(path);
    },
    openVineyard() {
      this.currentId= -1
      this.RightMenuSelected = "vineyards";
      this.changeMenuTag(0);
      this.$router.replace({ name: "districts", params: { slug: 'vineyards'} });
    },
    openBeachfront() {
      this.currentId= -1
      this.RightMenuSelected = "beachfront";
      this.changeMenuTag(0);
      this.$router.replace({ name: "districts", params: { slug: 'beachfront'} });
    },
    async changeDistrict(id) {
      let Slugid = this.$route.params.slug;
      if (Slugid == id) return;
      this.dataLoading = false;
      this.RightMenuSelected = "Districts";
      await this.getDistrict(id);
      this.$router.replace({ name: "districts", params: { slug: id } });
      this.MyDistrict = new DistrictForm(this.$store.state.districtsModule.district);
      this.dataLoading = true;
      this.currentId = id;
      this.coverImage = this.districtImage(this.MyDistrict.cover);
      queueMicrotask(() => {
        this.changeMenuTag(0)
      })
    },
    changeMenuTag(i) {
      this.step = i;
      const el = document.querySelector('.wrapper');
      el.scroll(0, el.clientHeight * this.step);
    },
    stepsClass(i, item){
      let mobileHide = '';
      if(this.RightMenuSelected == 'vineyards'){
        if(i==4) mobileHide = ' md:hidden';
      }
      else{
        if(i==6) mobileHide = ' md:hidden';
      }
      const fill = item.image ? '' : 'fill-dirty-white';
      const sticky = i ? 'stick-to-center ' : '';
      
      const zindex = `z-${4-i}0`;
      return fill + sticky + zindex + mobileHide;
    },
    districtImage(name){
      return this.$mediaFile('districts', this.MyDistrict.id, name)
    }
  },
  computed: {
    districtSteps(){
      return [
        {
          image: this.coverImage,
          comp: DistrictPageCover,
          props:{
            class:'frontCover'
          }
        },
        {
          image: this.districtImage(this.MyDistrict.cover4),
          comp: DistrictPagePresents,
          props:{
            class:'frontPresents'
          }
        },
        {
          image: this.districtImage(this.MyDistrict.cover2),
          comp: DistrictPageAbout,
          props:{
            class:'frontAbout'
          }
        },
        {
          image: this.districtImage(this.MyDistrict.cover3),
          comp: DistrictPageDidYouKnow,
          props:{
            class:'frontDidYouKnow'
          }
        },
        {
          image: this.districtImage(this.MyDistrict.map),
          noOverlay:true,
          comp: DistrictPageVisit,
          props:{
            class:'frontVisit'
          }
        },
        {
          comp: DistrictPageListings,
          props:{
            class:'frontListings',
            typeListing: this.RightMenuSelected,
          }
        },
        {
          comp: DistrictPageMobile,
          props:{
            class:'frontDistricts md:hidden'
          }
        },
      ]
    },
    vineyardsSteps(){
      return [
        {
          image: "/img/vineyards/cover.jpg",
          comp: VineyardCover,
        },
        {
          image: "/img/vineyards/douro.jpg",
          comp: VineyardAbout,
          props:{
            vineyard:'Douro',
          }
        },
        {
          image: "/img/vineyards/alentejo.jpg",
          comp: VineyardAbout,
          props:{
            vineyard:'Alentejo',
          }
        },
        {
          comp: DistrictPageListings,
          props:{
            class:'frontListings',
            typeListing: this.RightMenuSelected,
          }
        },
        {
          comp: DistrictPageMobile,
          props:{
            class:'frontDistricts md:hidden'
          }
        },
      ]
    },
    beachfrontSteps(){
      return [
        {
          image: "/img/beachfront/cover.jpg",
          comp: BeachfrontCover,
        },
        {
          image: "/img/beachfront/comporta.jpg",
          comp: BeachfrontAbout,
          props:{
            beachfront:'Comporta',
          }
        },
        {
          image: "/img/beachfront/algarve.jpg",
          comp: BeachfrontAbout,
          props:{
            beachfront:'Algarve',
          }
        },
        {
          image: "/img/beachfront/caparica.jpg",
          comp: BeachfrontAbout,
          props:{
            beachfront:'Caparica',
          }
        },
        {
          image: "/img/beachfront/matosinhos.jpg",
          comp: BeachfrontAbout,
          props:{
            beachfront:'Matosinhos',
          }
        },
        {
          comp: DistrictPageListings,
          props:{
            class:'frontListings',
            typeListing: this.RightMenuSelected,
          }
        },
        {
          comp: DistrictPageMobile,
          props:{
            class:'frontDistricts md:hidden'
          }
        },
      ]
    },
    steps(){
      const dictionary = {
        Districts: this.districtSteps,
        vineyards: this.vineyardsSteps,
        beachfront: this.beachfrontSteps,
      }
      return dictionary[this.RightMenuSelected]
    },
    currentStep(){
      return this.step
    },
    getMyDistrict() {
      return this.MyDistrict;
    },
    districtsItems() {
      const retrievedData = this.$store.state.districtsModule.districts;
      return Object.keys(retrievedData).map(
        (k) => new District(retrievedData[k])
      ).sort((a, b) => a.sort_id - b.sort_id);
    },
  },
};
</script>
