<template>
    <div class="relative leading-tight wrapper">
        <div class="grid grid-cols-3 grid-wrapper">
            <div class="text-3xl md:text-sectionTitle font-blackMango text-white font-black tracking-widest">
                <span>{{$t('Districts.PageAbout.WeChoose')}} {{ beachfront }}</span>
            </div>
            <div class="leading-loose text-baselg font-light font-euclid text-white tracking-wider text-justify">
                <span class="text-justify font-light">{{ getTextDescription(0) }}</span>
            </div>
            <div class="leading-loose text-baselg font-euclid text-white tracking-wider text-justify">
                <span class="text-justify font-light">{{ getTextDescription(1) }}</span>
            </div>
        </div>
    </div>
</template>
<style scoped>
  .wrapper{
    width: 90%;
    margin: 0 auto;
  }
  .grid-wrapper{
    gap:0 2rem;
  }
</style>
<script>

     export default {

        name: "Beachfront",
        props: ['beachfront'],
        methods:{
            getTextDescription(n){
                if(this.beachfront == 'Comporta'){
                    if(n == 0)
                        return this.$t('Beachfront.Comporta.description_p1')
                    else 
                        return this.$t('Beachfront.Comporta.description_p2')
                }else if(this.beachfront == 'Algarve'){
                    if(n == 1)
                        return ''
                        
                    return this.$t('Beachfront.Algarve.description_p1')
                }else if(this.beachfront == 'Caparica'){
                    if(n == 1)
                        return ''

                    return this.$t('Beachfront.Caparica.description_p1')
                }else{
                    if(n == 0)
                        return this.$t('Beachfront.Matosinhos.description_p1')
                    else 
                        return this.$t('Beachfront.Matosinhos.description_p2')
                }
            }
            
        }
     }
</script>
