<template>     
    <div>
        <p class="tracking-widest font-bold leading-3 md:leading-0 font-blackMango text-base lg:text-baselg md:text-baselg"> {{ putText }}</p>      
    </div>  
</template>

<script>
export default {
    name: "DistrictPageCardBody",
    props: ['putText']
}
</script>
