<template>
    <div class="relative leading-tight wrapper">
        <div class="presents-wrapper">
            <div class="text-xl md:text-3xl mb-4 md:mb-0 md:text-sectionTitle font-blackMango text-white font-black tracking-widest">
                <span>{{$t('Districts.PagePresent.WePresent')}} {{defineDistrict($processLanguage(MyDistrict.name))}}</span>
            </div>
            <div class="leading-2 md:leading-loose text-sm md:text-baselg font-normal font-euclid text-white tracking-wider md:text-justify">
                <p v-for="(item, i) of divideDescription(0)" :key="i" class="text-justify font-normal presents-text">{{ 
                  item
                }}</p>
            </div>
        </div>
    </div>
</template>
<style scoped>
  @media (min-width:721px){
    .presents-wrapper{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 50%;
      margin: 0 auto;
      text-align: center;
    }
    .presents-wrapper > div:first-child{
      margin-bottom: 30px;
    }
  }
  .presents-text{
    line-height: 33px;
    text-align: center;
    white-space: pre-line
  }
  .wrapper{
    width: 90%;
    margin: 0 auto;
  }
  @media (max-width:720px){
    .wrapper{
      width: 90%;
      margin-top: auto;
      padding-bottom: 1.5rem;
    }
    .presents-text{
      line-height: 15px;
      text-align: left;
    }
  }
  .grid-wrapper{
    gap:0 2rem;
  }
</style>
<script>
     export default {
        name: "DistrictPagePresents",
        props:['MyDistrict'],
        methods:{
          divideDescription(p){
            const dividedText =  this.$processLanguage(this.MyDistrict.presents).split('\r\n\r\n');
            const listText = dividedText[p]?.split('\n');
            return listText;
          },
          defineDistrict(d){
            if(d == "Algarve" || d == "Porto")
              return "o " + d

            return d
          }
        },
     }
</script>
