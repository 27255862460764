<template>
    <div class="absolute flex right-0">
        <span class="text-base text-right ml-4 2xl:ml-10">
            <a @click="changeMenuTag(0)" class="cursor-pointer"
            :class="currentStep == 0 && 'font-bold'">
                {{$t('Districts.RightMenu.Cover')}}
            </a>
        </span>
        <span class="text-base text-right ml-4 2xl:ml-10">
            <a @click="changeMenuTag(1)" class="cursor-pointer"
            :class="currentStep == 1 && 'font-bold'">
                Douro
            </a>
        </span>
        <span class="text-base text-right ml-4 2xl:ml-10">
            <a @click="changeMenuTag(2)" class="cursor-pointer"
            :class="currentStep == 2 && 'font-bold'">
                Alentejo
            </a>
        </span>
        <span class="text-base text-right ml-4 2xl:ml-10">
            <a @click="changeMenuTag(3)" class="cursor-pointer"
            :class="currentStep == 3 && 'font-bold'">
                {{$t('Districts.RightMenu.Listings')}}
            </a>
        </span>
    </div>
</template>
<script>
export default {
        name: "VineyardRightMenu",
        props:['currentStep'],
        methods:{
            changeMenuTag(t){
                this.$emit('change-menu-tag', t)
            }
        }
}
</script>
