<template>
    <!-- <div class="z-30  w-screen lg:w-3/4 leading-tight">
        <div class="grid grid-cols-2">
            <div class="text-3xl md:text-hero font-blackMango text-white uppercase">
                    <p class="ml-6">We choose</p>
                </transition>
                <transition mode="out-in" name="slide-fade">
                    <p class="ml-6">  {{ vineyard }} </p>
                </transition>
            </div>
            <div class="grid grid-cols-2 -ml-36">
                <div class="leading-loose text-sm font-euclid text-white tracking-wider -mt-14 mr-10">
                    <p class="text-justify"> {{ getTextDescription(0) }}</p>
                </div>
                <div class="leading-loose text-sm font-euclid text-white tracking-wider mr-5">
                    <p class="text-justify -mb-4">{{ getTextDescription(1) }}</p>
                </div>
            </div>
        </div>
    </div> -->
    <div class="relative leading-tight wrapper ">
        <div class="grid grid-cols-3 grid-wrapper">
            <div class="text-3xl md:text-sectionTitle font-blackMango text-white font-black tracking-widest">
                <span>{{$t('Districts.PageAbout.WeChoose')}} {{ vineyard }}</span>
            </div>
            <div class="leading-loose text-baselg font-light font-euclid text-white tracking-wider text-justify">
                <span class="text-justify font-light">{{ getTextDescription(0) }}</span>
            </div>
            <div class="leading-loose text-baselg font-euclid text-white tracking-wider text-justify">
                <span class="text-justify font-light">{{ getTextDescription(1) }}</span>
            </div>
        </div>
    </div>
</template>
<style scoped>
  .wrapper{
    width: 90%;
    margin: 0 auto;
  }
  .grid-wrapper{
    gap:0 2rem;
  }
</style>
<script>
     export default {
        name: "VineyardAbout",
        props: ['vineyard'],
        methods:{
            getTextDescription(n){
                if(this.vineyard == 'Douro'){
                    if(n == 0)
                        return this.$t('Vineyards.Douro.description_p1')
                    else 
                        return this.$t('Vineyards.Douro.description_p2')
                }else{
                    if(n == 0)
                        return this.$t('Vineyards.Alentejo.description_p2')
                    else 
                        return this.$t('Vineyards.Alentejo.description_p2')
                }
            }
        }
     }
</script>
