<template>
    <div class="w-full md:h-full container-visit">
        <div class="wrapper-visit">        
            <div class="flex flex-col glass-effect glass-fallback item-visit">
                <div class="text-xl md:text-xl font-blackMango text-black font-bold">
                    {{$t('Districts.PageVisit.T1')}}
                </div>
                <div class="text-xl md:text-xl font-blackMango text-black mb-4 font-bold">
                    {{$t('Districts.PageVisit.T2')}}
                </div>
                <div class="text-sm font-euclid tracking-widest text-black" v-for="text in getToVisit" :key="text">
                    <p>{{text}}</p><br>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
.container-visit{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.wrapper-visit {
  margin: 0;
  height: auto;
  width: 90%;
  display: block;
}
.item-visit{
  position: relative;
  width: 50%;
  min-width: max-content;
  padding: 3rem;
  border-radius: 25px;
}
@media (max-width: 720px){
    .container-visit{
        display:block;
    }
    .wrapper-visit {
        margin: 0;
        height: auto;
        width: 100%;
        display: block;
    }
    .item-visit{
        position: relative;
        width: 100%;
        min-width: auto;
        padding: 0 10%;
        padding-bottom: 1.5rem;
        border-radius: 0;
    }
}
</style>
<script>
     export default {
        name: "DistrictPageVisit",
        props:['MyDistrict'],
        computed:{
            getMyDistrict(){
                return this.MyDistrict
            },
            getToVisit(){
                let textString = this.$processLanguage(this.MyDistrict.to_visit).split('\r\n\r\n')
                // If it is one, it means it was succesfully readed from server
                // If not, it means that It was a copy and paste to the database. That means there are \r\n
                if(textString.length == 1)
                    textString = this.$processLanguage(this.MyDistrict.to_visit).split('\n');

                return textString
            }
        }

     }
</script>