<template>
    <div class="z-30 text-lg md:text-hero font-blackMango w-11/12 md:w-full pb-4 md:pb-0 text-white uppercase leading-tight mt-auto md:mt-20">
        <DistrictsPageCardTitle :name="getMyDistrict.name" />
        <div class="md:flex justify-center w-full mt-4 md:mt-12 xl:mt-20">
            <DistrictsPageCardBody :putText="$processLanguage(getMyDistrict.districts1)" />
            
            <div class="hidden md:block rounded-full h-2 w-2 flex bg-bronze mt-2 ml-4 mr-2 lg:ml-8 lg:mr-6"></div>

            <DistrictsPageCardBody :putText="$processLanguage(getMyDistrict.districts2)" />

            <div class="hidden md:block rounded-full h-2 w-2 flex bg-bronze mt-2 ml-4 mr-2 lg:ml-6 lg:mr-6"></div>

            <DistrictsPageCardBody :putText="$processLanguage(getMyDistrict.districts3)" />

            <div class="hidden md:block rounded-full h-2 w-2 flex bg-bronze mt-2 ml-4 mr-2 lg:ml-6 lg:mr-6"></div>

            <DistrictsPageCardBody :putText="$processLanguage(getMyDistrict.districts4)" />

            <div class="hidden md:block rounded-full h-2 w-2 flex bg-bronze mt-2 ml-4 mr-2 lg:ml-6 lg:mr-6"></div>

            <DistrictsPageCardBody :putText="$processLanguage(getMyDistrict.districts5)" />
        </div>
        <div class="hidden md:flex justify-center w-full mt-12 xl:mt-20">
            <div class="text-center text-base">
                <img @mouseover="mouseOuverDPCover(getMyDistrict.cover)" :key="getMyDistrict.id" 
                class="ring-8 ring-white ring-opacity-25
                hover:scale-125 scale-90 transition-all duration-500 ease-in-out transform h-24 w-24 xl:h-32 xl:w-32 rounded-full object-cover" 
                
                :src="$mediaFile('districts', getMyDistrict.id, getMyDistrict.cover)">
               
            </div>
            <div class="text-center text-base mr-16 ml-16">
                <img @mouseover="mouseOuverDPCover(getMyDistrict.cover2)" :key="getMyDistrict.id" 
                class="ring-8 ring-white ring-opacity-25
                hover:scale-125 scale-90 transition-all duration-500 ease-in-out transform h-24 w-24 xl:h-32 xl:w-32 rounded-full object-cover"  :src="$mediaFile('districts', getMyDistrict.id, getMyDistrict.cover2)">
                
            </div>
            <div class="text-center text-base">
                <img @mouseover="mouseOuverDPCover(getMyDistrict.cover3)" :key="getMyDistrict.id" 
                class="ring-8 ring-white ring-opacity-25
                hover:scale-125 scale-90 transition-all duration-500 ease-in-out transform h-24 w-24 xl:h-32 xl:w-32 rounded-full object-cover"  :src="$mediaFile('districts', getMyDistrict.id, getMyDistrict.cover3)">
                
            </div>
            
        </div>
    </div>
</template>
<script>
    import DistrictsPageCardTitle from '@/components/districts/districtsPageComponents/DistrictsPageCardTitle'
    import DistrictsPageCardBody from '@/components/districts/districtsPageComponents/DistrictsPageCardBody'
     export default {

        name: "DistrictPageCover",
        components:{
            DistrictsPageCardTitle,
            DistrictsPageCardBody
        },
        props:['MyDistrict'],
        created(){
        },
        methods:{
            mouseOuverDPCover(path){
                this.$emit('change-cover', path)
            }
        },
        computed:{
            getMyDistrict(){
                return this.MyDistrict
            }
        }

     }
</script>
