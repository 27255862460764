<template>
    <div class="relative lg:w-3/4 h-3/6 leading-tight flex wrapper">
        <img 
            :key="$processLanguage(MyDistrict.name)" 
            class="hidden md:block w-1/3 h-full object-cover rounded-t-full border-14 border-gray window-img" 
            :src="$mediaFile(getFixedPath, '', 'janela')">
        <div class="md:ml-10 flex flex-col-reverse">            
            <div class="md:w-1/2 md:leading-loose text-sm md:text-baselg text-justify font-euclid text-white tracking-widest mr-5 didyouknow-desc">
                {{ $processLanguage(MyDistrict.did_you_know) }}
            </div>
            <p class="text-white text-xl font-black md:text-3xl mb-4 md:mb-0 md:text-hero font-blackMango ">{{$t('Districts.PageDidYouKnow.DidYouKnow')}}</p>
        </div>
    </div>
</template>
<style scoped>
  .wrapper{
    width: 90%;
    margin: 0 auto;
  }
  .window-img{
    width: auto;
    max-width: 200px;
    border-color: rgba(97,97,97, .8);
  }
  @media (max-width:720px){
    .wrapper{
      width: 90%;
      margin-top: auto;
      padding-bottom: 1.5rem;
    }
    .didyouknow-desc{
      line-height: 15px;
      text-align: left;
    }
  }
</style>
<script>
     export default {
        name: "DistrictPageDidYouKnow",
        props:['MyDistrict'],
        data(){
            return{
                fixedImagesPath: 'FIXED_IMAGES/DISTRICTS/',
            }
        },
        computed:{
            getFixedPath(){
                return this.fixedImagesPath + this.$processLanguage(this.MyDistrict.name)
            }
        }
     }
</script>
