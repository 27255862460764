<template>
    <div class="relative leading-tight about-wrapper">
        <div class="grid md:grid-cols-3 grid-wrapper">
            <div class="text-xl md:text-3xl mb-4 md:mb-0 md:text-sectionTitle font-blackMango text-white font-black tracking-widest">
                <span :key="$processLanguage(MyDistrict.name)">
                {{$t('Districts.PageAbout.WeChoose')}} {{defineDistrict($processLanguage(MyDistrict.name))}}</span>
            </div>
            <div class="md:leading-loose text-sm md:text-baselg font-light font-euclid text-white tracking-wider">
                <span class="text-justify font-light about-desc">{{ divideDescription(0) }}</span>
            </div>
            <div class="md:leading-loose text-sm md:text-baselg font-euclid text-white tracking-wider ">
                <span class="text-justify font-light about-desc">{{ divideDescription(1) }}</span>
            </div>
        </div>
    </div>
</template>
<style scoped>
  .about-wrapper{
    width: 90%;
    margin: 0 auto;
  }
  .grid-wrapper{
    gap:0 2rem;
  }
  @media (max-width:720px){
    .about-wrapper{
      width: 90%;
      margin-top: auto;
      padding-bottom: 1.5rem;
    }
    .about-desc{
      line-height: 15px;
      text-align: left;
    }
  }
</style>
<script>
     export default {
        name: "DistrictPageAbout",
        props:['MyDistrict'],
        methods:{
          divideDescription(p){
              let dividedText = this.$processLanguage(this.MyDistrict.description).split('\r\n\r\n')
              
              // If it is one, it means it was succesfully readed from server
              // If not, it means that It was a copy and paste to the database. That means there are \r\n
              if(dividedText.length == 1){
                  dividedText = this.$processLanguage(this.MyDistrict.description).split('\n')
                  dividedText =  dividedText.filter(e =>  e);
              }
              return dividedText[p]
          },
          defineDistrict(d){
            if(d == "Algarve" || d == "Porto")
              return "o " + d

            return d
          }
        }
     }
</script>
