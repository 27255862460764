<template>
    <div v-show="onCreated">
        <p :key="$processLanguage(name)" class="text-sectionTitle md:text-menulg xl:text-titleSubPage tracking-wide font-black md:text-center"> 
            {{$t('districts.ThisIsTitle')}} {{$processLanguage(name)}}
        </p>
    </div>
</template>

<script>
export default {
    name: "DistrictPageCardTitle",
    props: ['name'],
    data(){
        return {
            onCreated: false
        }
    },
    created(){
        this.onCreated = true
    }
}
</script>
