<template>
    <div class="relative leading-tight flex wrapper">
        <div class="flex flex-col justify-end">            
            <div class="lg:w-3/4">
                <p class="text-white text-xl md:text-hero font-blackMango uppercase font-bold">{{ $t('Beachfront.Cover.title') }}</p>
            </div>
        </div>
    </div>
</template>
<style scoped>
  .wrapper{
    width: 90%;
    height: 90%;
    margin: 0 auto;
  }
</style>
<script>
     export default {
        name: "BeachfrontCover",
     }
</script>
