<template>
    <div class="relative leading-tight about-wrapper">
        <div class="grid md:grid-cols-3 grid-wrapper">
            <DistrictsMobile
                v-if="districtsItems.length > 2"
                id="districts_content_2"
                :items="districtsItems"
            />

        </div>
    </div>
</template>
<style scoped>
  .about-wrapper{
    width: 100%;
    margin: 0 auto;
  }
  .grid-wrapper{
    gap:0 2rem;
  }
  @media (max-width:720px){
    .about-wrapper{
      width: 100%;
      margin-top: auto;
    }
    .about-desc{
      line-height: 15px;
      text-align: left;
    }
  }
</style>
<script>
    import DistrictsMobile from "@/views/pages/DistrictsMobile";
    import { District } from '@/store/structs/districts';
    import mixinDistrict from '@/models/districts/DistrictsModel';
     export default {
        name: "DistrictPageMobile",
        props:['MyDistrict'],
        mixins: mixinDistrict,
        components:{
            DistrictsMobile
        },
        data: function () {
            return {
                // If cookie true, it was accepted before
                vineyardInformation:{
                    id: 9998,
                    name:{
                        pt: 'Quinta de vinhos',
                        en: 'Vineyards'
                    },
                    description:{
                        pt: '',
                        en: ''
                    },
                    cover: "/img/vineyards/cover_HP.jpg",
                    cover2: "/img/vineyards/cover_HP.jpg",
                    cover3: "/img/vineyards/cover_HP.jpg",
                    kpis:{
                        population: null,
                        properties: null,
                        golf_club: null
                    },
                    districts_title1:{
                        pt: '',
                        en: '',
                    },
                    districts_title2:{
                        pt: '',
                        en: '',
                    },
                    districts1:{
                        pt: '',
                        en: '',
                    },
                    districts2:{
                        pt: '',
                        en: '',
                    },
                    districts3:{
                        pt: '',
                        en: '',
                    },
                    districts4:{
                        pt: '',
                        en: '',
                    },
                    districts5:{
                        pt: '',
                        en: '',
                    },
                    key_point1:{
                        pt: '',
                        en: '',
                    },
                    key_point2:{
                        pt: '',
                        en: '',
                    },
                    key_point3:{
                        pt: '',
                        en: '',
                    },
                    key_point4:{
                        pt: '',
                        en: '',
                    },
                    presents:{
                        pt: '',
                        en:''
                    },
                    sort_id: 9998
                },
                beachfrontInformation:{
                    id: 9999,
                    name:{
                        pt: 'Praia',
                        en: 'Beachfront'
                    },
                    description:{
                        pt: '',
                        en: ''
                    },
                    cover: "/img/beachfront/cover_HP.jpg",
                    cover2: "/img/beachfront/cover_HP.jpg",
                    cover3: "/img/beachfront/cover_HP.jpg",
                    kpis:{
                        population: null,
                        properties: null,
                        golf_club: null
                    },
                    districts_title1:{
                        pt: '',
                        en: '',
                    },
                    districts_title2:{
                        pt: '',
                        en: '',
                    },
                    districts1:{
                        pt: '',
                        en: '',
                    },
                    districts2:{
                        pt: '',
                        en: '',
                    },
                    districts3:{
                        pt: '',
                        en: '',
                    },
                    districts4:{
                        pt: '',
                        en: '',
                    },
                    districts5:{
                        pt: '',
                        en: '',
                    },
                    key_point1:{
                        pt: '',
                        en: '',
                    },
                    key_point2:{
                        pt: '',
                        en: '',
                    },
                    key_point3:{
                        pt: '',
                        en: '',
                    },
                    key_point4:{
                        pt: '',
                        en: '',
                    },
                    presents:{
                        pt: '',
                        en:''
                    },
                    sort_id: 9999
                }
            }
        },
        methods:{
          divideDescription(p){
              let dividedText = this.$processLanguage(this.MyDistrict.description).split('\r\n\r\n')
              
              // If it is one, it means it was succesfully readed from server
              // If not, it means that It was a copy and paste to the database. That means there are \r\n
              if(dividedText.length == 1){
                  dividedText = this.$processLanguage(this.MyDistrict.description).split('\n')
                  dividedText =  dividedText.filter(e =>  e);
              }
              return dividedText[p]
          },
          defineDistrict(d){
            if(d == "Algarve" || d == "Porto")
              return "o " + d

            return d
          }
        },
        computed:{
            districtsItems(){
                let retrievedData = this.$store.state.districtsModule.districts

                //retrievedData.push(this.vineyardInformation)
                //retrievedData.push(this.beachfrontInformation)

                retrievedData.sort((a, b) => a.sort_id - b.sort_id);

                return Object.keys(retrievedData).map(k =>
                    new District(retrievedData[k])
                )
            }
        }
     }
</script>
